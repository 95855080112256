﻿import {compositeDisposable} from "../utils/disposable";
import {expressEventListener} from "../common/html";

export function initColorSwap(containerEl: HTMLElement, swapEl?: HTMLElement) {
	if (!swapEl) swapEl = containerEl;
	const bgColor = swapEl.style.backgroundColor;
	const color = swapEl.style.color;

	const swapColor = () => {
		if (swapEl!.style.backgroundColor === bgColor) {
			swapEl!.style.backgroundColor = color;
			swapEl!.style.color = bgColor;
		} else {
			swapEl!.style.backgroundColor = bgColor;
			swapEl!.style.color = color;
		}
	};

	const disposableEvents = compositeDisposable (
		[
			expressEventListener(containerEl, 'mouseover', swapColor),
			expressEventListener(containerEl, 'mouseout', swapColor)
		]
	);

	return {
		dispose: () => disposableEvents.dispose()
	};
}
