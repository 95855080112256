import { expressQuerySelector } from "../common/html";
import { createEmptyState } from "../molecules/empty-state";
import {createProductGrid} from "./product-grid";

export function createProductGrids(grids: HTMLElement[]) {
	if (grids) {
		grids.forEach(grid => {
			const createdGrid = createProductGrid(grid, {});

			const emptyStateEl = expressQuerySelector<HTMLElement>(grid, '.technical-no-elements', false);
			if (emptyStateEl) createEmptyState(emptyStateEl, { onResetButtonClick: createdGrid.resetFilter, onShow: () => createdGrid.disableSort(true), onHide: () => createdGrid.disableSort(false) });

			return createdGrid;
		});
	}
}
