import {createGeneralPageEventsAndComponents} from "../components/page-general";
import {expressQuerySelector, getParentWithClassName, expressQuerySelectorAll} from "../common/html";
import {createHighlightedTiles} from "../components/highlighted-tiles";
import {createTruncate} from "../components/truncate";
import {initForm} from "../components/form";
import {wait} from "../utils/wait";
import {initColorSwap} from "../components/color-swap";
import {initVideoPlayer} from "../components/video-player";
import {createProductGrids} from "../components/product-grids";
import { createPromotionBar } from "../components/promotion-bar";
import { createImageDescription } from "../components/image-description";

window.addEventListener('load', () => {
	window.showMores = [];
	const imageDescriptions = expressQuerySelectorAll<HTMLElement>(document, '.technical-image-description');

	// Initialize the image descriptions
	imageDescriptions.forEach(el => createImageDescription(el));

	// Promotion bar
	const promotionBar = expressQuerySelector<HTMLElement>(document, '.technical-promotion-bar', false);
	if (promotionBar)
		createPromotionBar(promotionBar);

	createGeneralPageEventsAndComponents();

	// init highlighted tiles
	const highlightedTilesEl = expressQuerySelector<HTMLElement>(document, ".technical-highlighted-tiles", false);
	if (highlightedTilesEl) createHighlightedTiles(highlightedTilesEl);

	const headerComponents = expressQuerySelectorAll<HTMLElement>(document, '.technical-header-component');
	headerComponents.forEach(header => {
		const truncateEl = expressQuerySelector<HTMLElement>(header, '.technical-shave', false);
		if (!truncateEl) return;
		createTruncate(truncateEl, { lines: 2 });
	});

	// Init product grids
	const grids = expressQuerySelectorAll<HTMLElement>(document, ".technical-product-grid");
	if (grids) createProductGrids(grids);

	const colorSwapEls = expressQuerySelectorAll<HTMLElement>(document, ".technical-color-swap");
	colorSwapEls.forEach(el => {
		if (!getParentWithClassName(el, 'technical-product-grid-content'))
			initColorSwap(el, expressQuerySelector<HTMLElement>(el, ".m-product__button", true));
	});

	const videoTileEls = expressQuerySelectorAll<HTMLElement>(document, '.technical-video-tile');
	videoTileEls.forEach(el => {
		initVideoPlayer(el);
	});
});
