import {expressAddClass, expressEventListener, expressQuerySelector, expressRemoveClass} from "../common/html";
import {compositeDisposable} from "../utils/disposable";

export interface IEmptyState {
	readonly show: () => void;
	readonly hide: () => void;
	readonly dispose: () => void;
}

export interface IEmptyStateOptions {
	readonly onResetButtonClick: () => void;
	readonly onShow?: () => void;
	readonly onHide?: () => void;
}

export function createEmptyState(containerEl: HTMLElement, opts?: IEmptyStateOptions) {
	let disposable;
	const resetButtonEl = expressQuerySelector<HTMLElement>(containerEl, '.technical-reset-filters-empty-state', false);

	if (resetButtonEl && opts && opts.onResetButtonClick) {
		disposable = compositeDisposable([expressEventListener(resetButtonEl, 'click', opts.onResetButtonClick)]);
	}

	const show = () => {
		expressRemoveClass(containerEl, 'u-hide');
		opts && opts.onShow && opts.onShow();
	};

	const hide = () => {
		expressAddClass(containerEl, 'u-hide');
		opts && opts.onHide && opts.onHide();
	};

	return {
		show,
		hide,
		dispose: () => disposable && disposable.dispose(),
	} as IEmptyState;
}
